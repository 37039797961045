// import axiosInstance from "../../configs/axios/AxiosVonfiguration";
export const Columns = [
  {
      name: 'Association Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
    },
    {
        name: 'Court',
        selector: (row) => row.court.name,
        sortable: true,
    },
   
    
 
  
];

export const adminColumns = [
  {
    name: 'Admin Name',
    selector: (row) => row.userName,
    sortable: true,
  },
  {
    name: 'Email',
    selector: (row) => row.userEmail,
    sortable: true,
  },
  {
    name: 'Association Name',
    selector: (row) => row.associationName,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => {
      console.log('activated_via_email:', row.admin_status);
      return row.admin_status === true ? "Active" : "Pending - Link didn't activate yet";
    },
    sortable: true,
  },
];