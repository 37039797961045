// import React, { useState } from "react";
// import '../../styles/Directories/memberDirectoryNav.css';

// function MemberDirectoryNav() {
//     const [activeTab, setActiveTab] = useState('Home');
//     const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

//     const handleTabClick = (tabName) => {
//         setActiveTab(tabName);
//         setIsMobileMenuOpen(false);
//     };

//     const toggleMobileMenu = () => {
//         setIsMobileMenuOpen(!isMobileMenuOpen);
//     };

//     const handleCloseButtonClick = () => {
//         setIsMobileMenuOpen(false);
//     };

//     return (
//         <>
//             <div className='member-directory'>
//                 <div className='facebook-container'>
//                     <div className='facebook-subcontainer'>
//                         <div>

//                         </div>
//                         <div>
//                             <i className='bx bxl-facebook facebook-logo'></i>
//                             <i className='bx bxl-youtube youtube-logo'></i>
//                         </div>
//                     </div>
//                 </div>
//                 <div className='member-container'>
//                     <div className='member-subcontainer'>
//                         <div className='member-khcaimage'>
//                             <img src="http://www.khcaa.com/wp-content/uploads/2020/12/Header-Logo.png" alt="KHCAA" />
//                         </div>
//                         <div className='member-phone'>
//                             <p>Contact: +91-484-2393244</p>
//                         </div>
//                     </div>
//                 </div>
                
               
                

//                 <hr className="line" />
//                 <div className='member-nav'>
//                     <div className={`hamburger-menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`} onClick={toggleMobileMenu}>
//                         <div className='bar'></div>
//                         <div className='bar'></div>
//                         <div className='bar'></div>
//                     </div>
                    
//                     <nav className={`member-navigation ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                    
//                         <ul className={`nav-ul ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
//                         {isMobileMenuOpen && (
//                         <button  className="close-button-for-membernav" onClick={handleCloseButtonClick}>
//                             <i class='bx bx-window-close'></i>
//                         </button>
//                     )}
//                             <li onClick={() => handleTabClick("Home")} className={activeTab === "Home" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Home</li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("About us")} className={activeTab === "About us advocate-directory-nav-item" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>
//                                 About us
//                                 <ul class="submenu">
//                                     <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("About KHCAA")}>About KHCAA</li>
//                                     <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("History of BAR")}>History of BAR</li>
//                                     <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("President's Desk")}>President's Desk</li>
//                                     <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("Executive Committee")}>Executive Committee</li>
//                                     <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("Former Presidents & Secretaries")}>Former Presidents & Secretaries</li>
//                                     <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("Voters List")}>Voters List</li>
//                                 </ul>
//                             </li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Notice")} className={activeTab === "Notice" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Notice</li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Resource")} className={activeTab === "Resource" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Resource
//                                 <ul className='submenu'>
//                                     <li className="advocate-navbar-submenulist">KHCAA Annual Day 2023</li>
//                                     <li className="advocate-navbar-submenulist">Rules & Regulations</li>
//                                     <li className="advocate-navbar-submenulist">GJCC Byelaws</li>
//                                     <li className="advocate-navbar-submenulist">Medical Aid Scheme</li>
//                                     <li className="advocate-navbar-submenulist">Social Security Scheme-|</li>
//                                     <li className="advocate-navbar-submenulist">Social Security Scheme-||</li>
//                                     <li className="advocate-navbar-submenulist">Downloads</li>
//                                 </ul>
//                             </li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Gallery")} className={activeTab === "Gallery" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Gallery
//                                 <ul className='submenu'>
//                                     <li className="advocate-navbar-submenulist">Photo Gallery</li>
//                                     <li className="advocate-navbar-submenulist">Video Gallery</li>
//                                 </ul>
//                             </li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Calender")} className={activeTab === "Calender" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Calender</li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Members Directory")} className={activeTab === "Members Directory" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Members Directory</li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Contact")} className={activeTab === "Contact" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Contact</li>
//                             <li className="advocate-directory-nav-line">|</li>
//                             <li onClick={() => handleTabClick("Member Login")} className={activeTab === "Member Login" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Member Login</li>
//                         </ul>
//                     </nav>
                    
//                 </div>
//                 <hr className="under-line-navbar" />
                


//             </div>
//         </>
//     );
// }

// export default MemberDirectoryNav;






import React, { useState } from "react";
import '../../styles/Directories/memberDirectoryNav.css';

function MemberDirectoryNav() {
    const [activeTab, setActiveTab] = useState('Members Directory');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        setIsMobileMenuOpen(false);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const handleCloseButtonClick = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <>
            <div className='member-directory'>
                <div className='facebook-container'>
                    <div className='facebook-subcontainer'>
                        <div>

                        </div>
                        <div>
                            <i className='bx bxl-facebook facebook-logo'></i>
                            <i className='bx bxl-youtube youtube-logo'></i>
                        </div>
                    </div>
                </div>
                <div className='member-container'>
                    <div className='member-subcontainer'>
                        <div className='member-khcaimage'>
                            <img src="http://www.khcaa.com/wp-content/uploads/2020/12/Header-Logo.png" alt="KHCAA" />
                        </div>
                        <div className='member-phone'>
                            <p>Contact: +91-484-2393244</p>
                        </div>
                    </div>
                </div>
                
               
                

                <hr className="line" />
                <div className='member-nav'>
                    <div className={`hamburger-menu ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`} onClick={toggleMobileMenu}>
                        <div className='bar'></div>
                        <div className='bar'></div>
                        <div className='bar'></div>
                    </div>
                    
                    <nav className={`member-navigation ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                    
                        <ul className={`nav-ul ${isMobileMenuOpen ? 'mobile-menu-open' : ''}`}>
                        {isMobileMenuOpen && (
                        <button  className="close-button-for-membernav" onClick={handleCloseButtonClick}>
                            <i class='bx bx-window-close'></i>
                        </button>
                    )}
                            <a href="https://khcaa.com" target="_blank" rel="noopener noreferrer">         
                            <li onClick={() => handleTabClick("Home")} className={activeTab === "Home" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Home</li>
                            </a>
                            <li className="advocate-directory-nav-line">|</li>
                            <li onClick={() => handleTabClick("About us")} className={activeTab === "About us advocate-directory-nav-item" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>
                                About us
                                <ul class="submenu">
                                    <a href="https://www.khcaa.com/about/about-khcaa/" target="_blank" rel="noopener noreferrer">       
                                    <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("About KHCAA")}>About KHCAA</li>
                                    </a>
                                    <a href="https://www.khcaa.com/about/history-of-bar/" target="_blank" rel="noopener noreferrer">        
                                    <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("History of BAR")}>History of BAR</li>
                                    </a>
                                    <a href="https://www.khcaa.com/about/presidents-desk/" target="_blank" rel="noopener noreferrer">              
                                    <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("President's Desk")}>President's Desk</li>
                                    </a>
                                    <a href="https://www.khcaa.com/about/executive-committee/" target="_blank" rel="noopener noreferrer">         
                                    <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("Executive Committee")}>Executive Committee</li>
                                    </a>
                                    <a href="https://www.khcaa.com/about/former-presidents-secretaries/" target="_blank" rel="noopener noreferrer">      
                                    <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("Former Presidents & Secretaries")}>Former Presidents & Secretaries</li>
                                    </a>
                                    <a href="https://www.khcaa.com/about/voters-list/" target="_blank" rel="noopener noreferrer">      
                                    <li className="advocate-navbar-submenulist" onClick={() => handleTabClick("Voters List")}>Voters List</li>
                                    </a>
                                </ul>
                            </li>
                            <li className="advocate-directory-nav-line">|</li>
                            <a href="https://www.khcaa.com/news/" target="_blank" rel="noopener noreferrer">
                            <li onClick={() => handleTabClick("Notice")} className={activeTab === "Notice" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Notice</li>
                            </a>
                            <li className="advocate-directory-nav-line">|</li>
                            <li onClick={() => handleTabClick("Resource")} className={activeTab === "Resource" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Resource
                                <ul className='submenu'>
                                    <a href="https://www.khcaa.com/resources/khcaa-annual-day-2023/" target="_blank" rel="noopener noreferrer">          
                                    <li className="advocate-navbar-submenulist">KHCAA Annual Day 2023</li>
                                    </a>
                                    <a href="https://www.khcaa.com/about/rules-regulations/" target="_blank" rel="noopener noreferrer">        
                                    <li className="advocate-navbar-submenulist">Rules & Regulations</li>
                                    </a>
                                    <a href="https://www.khcaa.com/gjcc-byelaws/" target="_blank" rel="noopener noreferrer">
                                    <li className="advocate-navbar-submenulist">GJCC Byelaws</li>
                                    </a>
                                    <a href="https://www.khcaa.com/medical-aid-scheme/" target="_blank" rel="noopener noreferrer">
                                    <li className="advocate-navbar-submenulist">Medical Aid Scheme</li>
                                    </a>
                                    <a href="https://www.khcaa.com/social-security-scheme-i/" target="_blank" rel="noopener noreferrer">   
                                    <li className="advocate-navbar-submenulist">Social Security Scheme-I</li>
                                    </a>
                                    <a href="https://www.khcaa.com/social-security-scheme-ii/" target="_blank" rel="noopener noreferrer">
                                    <li className="advocate-navbar-submenulist">Social Security Scheme-II</li>
                                    </a>
                                    <a href="https://www.khcaa.com/resources/downloads/" target="_blank" rel="noopener noreferrer">
                                    <li className="advocate-navbar-submenulist">Downloads</li>
                                    </a>
                                </ul>
                            </li>
                            <li className="advocate-directory-nav-line">|</li>
                            <li onClick={() => handleTabClick("Gallery")} className={activeTab === "Gallery" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Gallery
                                <ul className='submenu'>
                                    <a href="https://www.khcaa.com/photo-gallery/" target="_blank" rel="noopener noreferrer">
                                    <li className="advocate-navbar-submenulist"> Photo Gallery</li>
                                    </a>
                                    <a href="https://www.khcaa.com/video-gallery/" target="_blank" rel="noopener noreferrer">  
                                    <li className="advocate-navbar-submenulist">Video Gallery</li>
                                    </a>
                                </ul>
                            </li>
                            <li className="advocate-directory-nav-line">|</li>
                            <a href="https://www.khcaa.com/calendar/" target="_blank" rel="noopener noreferrer">
                            <li onClick={() => handleTabClick("Calender")} className={activeTab === "Calender" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Calender</li>
                            </a>
                            <li className="advocate-directory-nav-line">|</li>
                            <a href="https://khcaa.org/#/member/directory" target="_blank" rel="noopener noreferrer">     
                            <li onClick={() => handleTabClick("Members Directory")} className={activeTab === "Members Directory" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Members Directory</li>
                            </a>
                            <li className="advocate-directory-nav-line">|</li>
                            <a href="https://www.khcaa.com/pages/contact/" target="_blank" rel="noopener noreferrer">
                            <li onClick={() => handleTabClick("Contact")} className={activeTab === "Contact" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Contact</li>
                            </a>
                            <li className="advocate-directory-nav-line">|</li>
                            <a href="https://khcaa.org/#/Advocate/login" target="_blank" rel="noopener noreferrer">
                            <li onClick={() => handleTabClick("Member Login")} className={activeTab === "Member Login" ? "active advocate-directory-nav-item" : "advocate-directory-nav-item"}>Member Area</li>
                            </a>
                        </ul>
                    </nav>
                    
                </div>
                <hr className="under-line-navbar" />
                


            </div>
        </>
    );
}

export default MemberDirectoryNav;