import React from 'react'

function BlankPage() {
  return (
    <div>
      this is blank pages
    </div>
  )
}

export default BlankPage
