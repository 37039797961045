import React from 'react'

function BlankPageId() {
  return (
    <div>
      this is blank pages id
    </div>
  )
}

export default BlankPageId
