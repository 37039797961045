import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../../../styles/advocates/memberprofile.css';
import axiosInstance from '../../../configs/axios/AxiosVonfiguration';
import LoadingAnimation from '../../loader/LoadingAnimation';
import { IoArrowUndoSharp } from "react-icons/io5";
import adv_default from '../../../assets/images/adv_default.jpg'
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';





function MemberProfile() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [advocateId, setAdvocateId] = useState(id)
  const navigte = useNavigate()
  const [storedSearchQuery, setStoredSearchQuery] = useState('');
  const [noDataMessage, setNoDataMessage] = useState('');
  const itemsPerPage = 16;
  const [searchQuery, setSearchQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [dataLength, setDataLength] = useState(1)
  const [searchDataList, setSearchDataList] = useState([])

  const storedCurrentPage = localStorage.getItem('currentPage');
  console.log(storedCurrentPage, '11111111storedCurrentPage')
  const [currentPage, setCurrentPage] = useState(storedCurrentPage ? parseInt(storedCurrentPage, 10) : 0);
  console.log(currentPage, '111111111currentPage')
  const componentRef = useRef();


  const fetchData = async (query) => {
    try {
      setLoading(true)
      const response = await axiosInstance.get('advocates/random/list', {
        params: {
          search: query !== '' ? query : null,
          page: pageNumber + 1
        }
      });
      console.log(response.data.length, 'length')
      if (response.data.length === 0) {
        console.log('No data found')
        setDataLength(0)
      } else {
        setDataLength(response.data.length)
      }
      setLoading(false);
      setSearchDataList(response.data);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    const advocateProfileview = async () => {
      try {
        const response = await axiosInstance.get(`advocates/editform-advocate/${advocateId}`);
        console.log('uuuuuuuuuuuuuuuuuuuuyyyyyyyyyyyy')
        console.log('opopopopopopopop  ', response.data)
        setData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    advocateProfileview();
  }, [advocateId]);

  const handleNavigate = () => {
    navigte('/member/directory')
  }

  const handleNavigateDetailView = () => {
    console.log('111111111111111111111111111111')
    // navigte(/member/detailview/${advocateId})
    window.location.reload()
  }

  // const handliPrint=()=>{
  //   window.print()
  // }
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    localStorage.removeItem('currentPage', selected);

    console.log(selected, '333333333333333333333333333333333');
    if (Math.ceil(data.length / itemsPerPage) <= selected) {
      console.log('Fetching more data...');
      var query = '';
      const page_number_id = selected + 1;
      localStorage.setItem('currentPage', selected);
      setCurrentPage(selected)
      window.location.reload();
    }
  };
  const displayedData = searchDataList.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  const handleSearchButtonClick = () => {
    setStoredSearchQuery(searchQuery);
    setPageNumber(0);
    setNoDataMessage('');
    fetchData(searchQuery)
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    // fetchData(storedSearchQuery);

    let cards = document.querySelectorAll('.variety-card');
    cards.forEach((card) => {
      card.classList.add('fade-in');
    });
  }, [storedSearchQuery]);

  const handleViewProfileClick = (advocateId) => {
    setStoredSearchQuery('');
    setSearchQuery('')
    setAdvocateId(advocateId)
    console.log('iddddd', advocateId)

    // Navigate to the advocate's detail view
    navigte(`/member/detailview/${advocateId}`);
  };




  if (loading) {
    return (
      <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999' }}>
        <LoadingAnimation color="#3498db" loading={loading} size={15} />
      </div>
    );
  }


  if (storedSearchQuery) {
    return (
      <>
        {/* <div className="khcaaDirectorylist-parent-container">
          <div className="khcaaDirectorylist-search-container">
            <div className="khcaaDirectorylist-findlawyer-container">
              <div className="khcaaDirectorylist-findlawyer">
                <div className="pagination-container">
                  
                  <div className='advocate-page-content-back-and-search-parent-container'>
                    <div className='advocate-page-content-back-and-search-parent-subcontainer'>

                   

                 
                  <div className='advocate-page-content-back-option-child'>

                    <button className='advocate-icon-to-go-back-to-directory-second-back' onClick={handleNavigateDetailView} ><span><i class='bx bx-arrow-back'></i></span>Back</button>
                   
                  </div>
                  <div>
                  <form onSubmit={handleSearchButtonClick}>
                <div className="KhcaaDirectorylist-searchlawyer">
                  <input
                    type="text"
                    className="khcaaDirectorylist-input-text"
                    placeholder="Search lawyer"
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                  />
                  <button
                    type="submit" 
                    className="khcaaDirectorylist-search-button"
                  >
                    Search
                  </button>
                </div>
              </form>

                  </div>
                  </div>
                </div>
               
              </div>
              
              </div>
            </div>
          </div>
        </div> */}
        <div className='khcaa-directory-detailview-second-search-parent-container'>
          <div className='khcaa-directory-detailview-second-search-parent-sub-container'>

          

          <div>
            <button className='advocate-icon-to-go-back-to-directory-second-back-for-search' onClick={handleNavigateDetailView} ><span><i class='bx bx-arrow-back'></i></span>Back</button>
          </div>

          <div>


            <form onSubmit={handleSearchButtonClick}>
              <div className="KhcaaDirectorylist-searchlawyer">
                <input
                  type="text"
                  className="khcaaDirectorylist-input-text"
                  placeholder="Search lawyer"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button
                  type="submit"
                  className="khcaaDirectorylist-search-button"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          </div>
        </div>



        {dataLength == 0 &&
          <div className="No-data-container" style={{ display: 'flex', marginTop: '2em', alignItems: 'center', justifyContent: 'center' }}>
            <h3>No data Found....</h3>
          </div>
        }





        <div className="khcaaDirectorylist-membercard-lists">
          <div className="khcaaDirectorylist-membercars-for-advocates">
            {displayedData.map((advocate) => {
              // Calculate the length of the advocate's name
              const isLongName = advocate.user.name.length > 15;
              const isLongEmail = advocate.user.email.length > 25;

              // Define classes using the classnames library
              const nameClasses = classNames('khcaaDirectory-advocate-name ', {
                'long-name': isLongName,
              });
              const emailClasses = classNames('khcaaDirectory-advocate-email', {
                'long-email': isLongEmail,
              });

              // Define additional styles for the long name case
              const longNameStyles = isLongName ? { fontSize: '21px' } : {};
              const longEmailStyles = isLongEmail ? { fontSize: '15px' } : {};
              // const viewProfile = isLongName ? { marginBottom: '10px' } : { marginBottom: '18px' };


              return (
                <div className="khcaaDirectorylist-profile-card" key={advocate.id}>
                  <img
                    className="variety-card-image"
                    src={
                      advocate.profile_image
                        ? advocate.profile_image_url
                        : advocate.image_link === 0
                          ? adv_default
                          : advocate.image_link ? advocate.image_link : adv_default
                    }
                    alt="img"
                  />
                  <div className="profile-details">
                    <h2 className={nameClasses} style={longNameStyles}>
                      {advocate.user.name}
                    </h2>
                    <p className="khcaaDirectory-advocate-court">Advocate, High Court of Kerala</p>
                    <p className="khcaaDirectory-advocate-number">{advocate.phone}</p>
                    <p className={emailClasses} style={longEmailStyles}>{advocate.user.email}</p>
                    {/* <Link className="khcaaDirectory-advocate-viewprofile" to={/member/detailview/${advocate.id}} style={{ color: '#B1001E', fontWeight: 'bold' }}>
                      View Profile <strong>&rarr;</strong>
                    </Link> */}

                    <Link
                      className="khcaaDirectory-advocate-viewprofile"
                      onClick={() => handleViewProfileClick(advocate.id)}
                      style={{ color: '#B1001E', fontWeight: 'bold' }}
                    >
                      View Profile <strong>&rarr;</strong>
                    </Link>

                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="khcaa-directory-paginate">
          <ReactPaginate
            previousLabel={<strong>&larr;</strong>}
            nextLabel={<strong>&rarr;</strong>}
            breakLabel={'.....'}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(searchDataList.length / itemsPerPage)}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            // previousLinkClassName={"pagination-item previous-link"}
            // nextLinkClassName={"pagination-item next-link"}
            disabledClassName={"pagination-item-disabled"}
            subContainerClassName={'pages pagination'}
            forcePage={currentPage}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            displayPages={1}
            activeClassName={'active'}
          />

        </div>

      </>
    );

  }


  return (

    <div className="page-content page-container" id="page-content">
      <div className='advocate-page-content-back-option-parent'>
        <div className='advocate-page-content-back-option-child'>
          <div>
            <button className='advocate-icon-to-go-back-to-directory' onClick={handleNavigate}><span><i class='bx bx-arrow-back'></i></span>Back</button>
          </div>
          <div>
            <form onSubmit={handleSearchButtonClick}>
              <div className="KhcaaDirectorylist-searchlawyer">
                <input
                  type="text"
                  className="khcaaDirectorylist-input-text"
                  placeholder="Search lawyer"
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                />
                <button
                  type="submit" // Specify the type as "submit" for the button
                  className="khcaaDirectorylist-search-button"
                >
                  Search
                </button>
              </div>
            </form>

          </div>
        </div>
        {/* <div>
            <input type="text" name="" id="" placeholder='Find lawyer...' className='advocate-icon-to-go-back-and-search-lawyer'/>
            <button className='btn btn-danger fs-6 ml-2'>Search</button>
          </div> */}






      </div>
      <div className="container" ref={componentRef}>

        <div className="row justify-content-center">

          <div className="col-md-12">

            <div className="card user-card-full d-flex flex-column flex-md-row">

              <div className="bg-c-lite-green user-profile col-md-3 mb-3 col-sm-12">

                <div className="text-center text-white">

                  <div className="m-b-25">
                    <img
                      style={{
                        width: '100% !important',
                        height: '300px !important',
                        objectFit: 'cover',
                      }}
                      src={data.profile_image ? data.profile_image_url : data.image_link ? data.image_link : adv_default}
                      className="img-radius"
                      alt="img"
                    />
                  </div>
                  <i className="mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
                </div>
              </div>
              <div className="col-md-9 col-sm-12">
                <div className="card-block">
                  <div className='advocate-social-media-site-and-name-parent'>
                    <div className='advocate-social-medial-and-name-child'>

                      <h2 className="f-w-600 khcaa-directory-advocate-first-name">
                        {data.user?.name}
                      </h2>
                    </div>
                    {/* <div>
                          <ul className='advocate-social-site-and-name-subcontainer-ul'>
                            <li className='advocate-social-site-and-name-subcontainer-li' ><a href={https://www.facebook.com/${data.insta_id}} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook" data-abc="true" >
                             <i className='bx bxl-facebook-square' ></i>
                           </a></li>
                            <li className='advocate-social-site-and-name-subcontainer-li'><a href={https://www.twitter.com/${data.insta_id}} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter" data-abc="true" >
                             <i className='bx bxl-twitter' ></i>
                           </a></li>
                            <li className='advocate-social-site-and-name-subcontainer-li'><a href={https://www.instagram.com/${data.insta_id}} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram" data-abc="true" >
                             <i className='bx bxl-instagram' ></i>
                           </a></li>
                            <li className='advocate-social-site-and-name-subcontainer-li'>
                              <a href={https://www.linkedin.com/${data.insta_id}} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="linkedin" data-abc="true" >
                             <i className='bx bxl-linkedin-square'></i>
                           </a>
                            </li>
                          </ul>
                    </div> */}


                    <div>
                      <ul className='advocate-social-site-and-name-subcontainer-ul'>

                        <li className='advocate-social-site-and-name-subcontainer-li'>
                          {data.fb_id ? (
                            <a href={`https://www.facebook.com/${data.fb_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook" data-abc="true" >
                              <i className='bx bxl-facebook-square'></i>

                            </a>
                          ) : (<i className='bx bxl-facebook-square'></i>)}


                        </li>


                        <li className='advocate-social-site-and-name-subcontainer-li'>
                          {data.twitter_id ? (
                            <a href={`https://www.twitter.com/${data.twitter_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter" data-abc="true" >
                              <i className='bx bxl-twitter'></i>

                            </a>
                          ) : (
                            <i className='bx bxl-twitter'></i>
                          )}
                        </li>


                        <li className='advocate-social-site-and-name-subcontainer-li'>
                          {data.insta_id ? (
                            <a href={`https://www.instagram.com/${data.insta_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram" data-abc="true" >
                              <i className='bx bxl-instagram'></i>

                            </a>
                          ) : (
                            <i className='bx bxl-instagram'></i>
                          )}
                        </li>


                        <li className='advocate-social-site-and-name-subcontainer-li'>
                          {data.linkedin_id ? (
                            <a href={`https://www.linkedin.com/${data.linkedin_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="LinkedIn" data-abc="true" >
                              <i className='bx bxl-linkedin-square'></i>

                            </a>
                          ) : (
                            <i className='bx bxl-linkedin-square'></i>
                          )}
                        </li>

                      </ul>
                    </div>





                  </div>

                  <h6 className="m-b-20 p-b-5 b-b-default f-w-600 khcaa-advocate-highcourt-name">Advocate, High Court of Kerala</h6>
                  <div className="row">
                    <div className="col-sm-12 col-md-6">
                      <p className="text-muted f-w-400">Email</p>
                      <div className="d-flex align-items-center khcaa-directory-advocate-email">
                        {/* <i className="mdi mdi-email-outline m-r-10"></i> */}
                        <h6 className="m-b-7 f-w-600 ">{data.user?.email}</h6>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 col-md-6">
                      <p className="m-b-10 f-w-600">Enrollment No:</p>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="text-muted f-w-400">{data.enrollment_id ? data.enrollment_id : '---'}</h6>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-6">
                      <p className="text-muted f-w-400">Phone</p>
                      <div className="d-flex align-items-center khcaa-directory-advocate-phone">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="m-b-7 f-w-600">{data.phone ? data.phone : '---'}</h6>
                      </div>
                    </div>
                    {/* <div className="col-sm-12 col-md-6" style={{marginTop:'18px'}}>
                      <p className="m-b-7 f-w-600">Phone</p>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="text-muted f-w-400">{data.phone ? data.phone : '---'}</h6>
                      </div>
                    </div> */}
                    {/* <div className="col-sm-12 col-md-6">
                      <p className="m-b-10 f-w-600">Phone</p>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="text-muted f-w-400">{data.phone ? data.phone : '---'}</h6>
                      </div>
                    </div> */}
                    {/* <div className="col-sm-12 col-md-6">
                      <p className="m-b-10 f-w-600">KHCAA Membership No:</p>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-phone m-r-10"></i>
                        <h6 className="text-muted f-w-400">{data.association_membership_id ? data.association_membership_id : '---'}</h6>
                      </div>
                    </div> */}
                    <div className="col-sm-12 col-md-6" style={{ marginTop: '20px' }}>
                      <p className="text-muted f-w-400">Official Address</p>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="m-b-8 f-w-600">{data.address ? data.address : '---'}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ marginTop: '20px' }}>
                      <p className="text-muted f-w-400">Residential Address</p>
                      <div className="d-flex align-items-center">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="m-b-8 f-w-600">{data.specialization ? data.specialization : '---'}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ marginTop: '20px' }}>
                      <p className="text-muted f-w-400">Status</p>
                      <div className="d-flex align-items-center khcaa-directory-advocate-enrollment-number">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="m-b-8 f-w-600">ACTIVE</h6>
                      </div>
                      {/* {data.enrollment_id ? data.enrollment_id : '---'} */}
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ marginTop: '20px' }}>
                      <p className="text-muted f-w-400">KHCAA Membership No</p>
                      <div className="d-flex align-items-center khcaa-directory-advocate-khcaa-membership">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="m-b-8 f-w-600">{data.khcaa_membership_number ? data.khcaa_membership_number : '---'}</h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ marginTop: '20px' }}>
                      <p className="m-b-8 f-w-600"></p>
                      <div className="d-flex align-items-center khcaa-directory-advocate-khcaa-membership-active">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="m-b-8 f-w-600" style={{ color: 'green' }}></h6>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6" style={{ marginTop: '20px' }}>
                      {/* <p className="m-b-8 f-w-600">Valid till </p>
                      <div className="d-flex align-items-center khcaa-directory-advocate-khcaa-membership-active">
                        <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
                        <h6 className="text-muted f-w-400">30/11/2024</h6>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-sm-12">
                      <p className="m-b-10 f-w-600">Official Address</p>
                      <h6 className="text-muted f-w-400">{data.specialization ? data.specialization : '---'}</h6>
                    </div>
                    <div className="col-sm-12">
                      <p className="m-b-10 f-w-600">Residential Address</p>
                      <h6 className="text-muted f-w-400">{data.address ? data.address : '---'}</h6>
                    </div>
                  </div> */}
                  <ul className="social-link list-unstyled m-t-40 m-b-10">
                    <div className='khcaa-advocate-directory-social-media-link-for-advocate'>

                      <div className='khcaa-advocate-directory-social-media-link-for-advocate-sub-container'>


                        <li >
                          <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-facebook'>

                            <i class='bx bxl-facebook-square' ></i>
                          </a>
                        </li>
                        <li>
                          <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-twitter'>
                            <i class='bx bxl-twitter' ></i>
                          </a>
                        </li>
                        <li>
                          <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-instagram'>
                            <i class='bx bxl-instagram' ></i>
                          </a>
                        </li>
                        <li>
                          <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="linkedin" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-linkedin'>
                            <i class='bx bxl-linkedin-square'></i>
                          </a>
                        </li>
                      </div>
                      <div className='khcaa-advocate-directory-social-media-link-for-advocate-sub-container-for-print'>
                        <li>
                          {/* <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="print" data-abc="true"> */}
                          <div onClick={handlePrint}>
                            <i className='bx bxs-printer khcaa-advocate-directory-social-media-link-for-print'></i>
                          </div>

                          {/* </a> */}
                        </li>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}





export default MemberProfile;


// import React, { useEffect, useState ,useRef} from 'react';
// import { useParams } from 'react-router-dom';
// import '../../../styles/advocates/memberprofile.css';
// import axiosInstance from '../../../configs/axios/AxiosVonfiguration';
// import LoadingAnimation from '../../loader/LoadingAnimation';
// import { IoArrowUndoSharp } from "react-icons/io5";
// import adv_default from '../../../assets/images/adv_default.jpg'
// import { useNavigate } from 'react-router-dom';
// import classNames from 'classnames';
// import ReactPaginate from 'react-paginate';
// import { Link } from 'react-router-dom';
// import { useReactToPrint } from 'react-to-print';





// function MemberProfile() {
//   const [data, setData] = useState({});
//   const [loading, setLoading] = useState(true);
//   const { id } = useParams();
//   const [advocateId, setAdvocateId] = useState(id)
//   const navigte = useNavigate()
//   const [storedSearchQuery, setStoredSearchQuery] = useState('');
//   const [noDataMessage, setNoDataMessage] = useState('');
//   const itemsPerPage = 16;
//   const [searchQuery, setSearchQuery] = useState('');
//   const [pageNumber, setPageNumber] = useState(0);
//   const [dataLength, setDataLength] = useState(1)
//   const [searchDataList, setSearchDataList] = useState([])

//   const storedCurrentPage = localStorage.getItem('currentPage');
//   console.log(storedCurrentPage,'11111111storedCurrentPage')
//   const [currentPage, setCurrentPage] = useState(storedCurrentPage ? parseInt(storedCurrentPage, 10) : 0);
//   console.log(currentPage,'111111111currentPage')
//   const componentRef = useRef();


//   const fetchData = async (query) => {
//     try {
//       setLoading(true)
//       const response = await axiosInstance.get('advocates/random/list', {
//         params: {
//           search: query !== '' ? query : null,
//           page: pageNumber + 1
//         }
//       });
//       console.log(response.data.length,'length')
//       if (response.data.length === 0){
//         console.log('No data found')
//         setDataLength(0)
//       }else{
//         setDataLength(response.data.length)
//       }
//       setLoading(false);
//       setSearchDataList(response.data);
//     } catch (error) {
//       setLoading(false);
//       console.error('Error fetching data:', error);
//     }
//   };


//   useEffect(() => {
//     const advocateProfileview = async () => {
//       try {
//         const response = await axiosInstance.get(`advocates/editform-advocate/${advocateId}`);
//         console.log('uuuuuuuuuuuuuuuuuuuuyyyyyyyyyyyy')
//         console.log('opopopopopopopop  ', response.data)
//         setData(response.data);
//         setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         console.error('Error fetching data:', error);
//       }
//     };

//     advocateProfileview();
//   }, [advocateId]);

//   const handleNavigate = () => {
//     navigte('/member/directory')
//   }

//   const handleNavigateDetailView = () => {
//     console.log('111111111111111111111111111111')
//     // navigte(`/member/detailview/${advocateId}`)
//     window.location.reload()
//   }

//   // const handliPrint=()=>{
//   //   window.print()
//   // }
//   const handlePrint = useReactToPrint({
//     content: () => componentRef.current,
//   });

//   const handlePageClick = ({ selected }) => {
//     setPageNumber(selected);
//     localStorage.removeItem('currentPage', selected);

//     console.log(selected, '333333333333333333333333333333333');
//     if (Math.ceil(data.length / itemsPerPage) <= selected) {
//        console.log('Fetching more data...');
//       var query = '';
//       const page_number_id  = selected + 1;
//       localStorage.setItem('currentPage', selected);
//       setCurrentPage(selected)
//       window.location.reload();
//     }
//   };
//   const displayedData = searchDataList.slice(
//     pageNumber * itemsPerPage,
//     (pageNumber + 1) * itemsPerPage
//   );

//   const handleSearchButtonClick = () => {
//     setStoredSearchQuery(searchQuery);
//     setPageNumber(0);
//     setNoDataMessage('');
//     fetchData(searchQuery)
//   };

//   const handleSearchInputChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   useEffect(() => {
//     // fetchData(storedSearchQuery);
    
//     let cards = document.querySelectorAll('.variety-card');
//     cards.forEach((card) => {
//       card.classList.add('fade-in');
//     });
//   }, [storedSearchQuery]);

//   const handleViewProfileClick = (advocateId) => {
//     setStoredSearchQuery('');
//     setSearchQuery('')
//     setAdvocateId(advocateId)
//     console.log('iddddd', advocateId)
  
//     // Navigate to the advocate's detail view
//     navigte(`/member/detailview/${advocateId}`);
//   };
  

  

//   if (loading) {
//     return (
//       <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999' }}>
//         <LoadingAnimation color="#3498db" loading={loading} size={15} />
//       </div>
//     );
//   }


//   if (storedSearchQuery){
//     return (
//       <>
//         <div className="khcaaDirectorylist-parent-container">
//           <div className="khcaaDirectorylist-search-container">
//             <div className="khcaaDirectorylist-findlawyer-container">
//               <div className="khcaaDirectorylist-findlawyer">
//                 <div className="pagination-container">
//                   {/* ... Pagination JSX ... */}
//                   <div className='advocate-page-content-back-option-child'>

//           {/* <button className='advocate-icon-to-go-back-to-directory' onClick={handleNavigateDetailView} ><span><i class='bx bx-arrow-back'></i></span>Back</button> */}
//                     {/* <button className='advocate-icon-to-go-back-to-directory' onClick={handleNavigateDetailView} style={{ position: 'absolute', top: 10, left: 10 }} ><span><i class='bx bx-arrow-back'></i></span>Back</button> */}

//         </div>
//                 </div>
//                 <p>Find Lawyer</p>
//               </div>
//               <form onSubmit={handleSearchButtonClick}>
//             <div className="KhcaaDirectorylist-searchlawyer">
//               <input
//                 type="text"
//                 className="khcaaDirectorylist-input-text"
//                 placeholder="Search lawyer"
//                 value={searchQuery}
//                 onChange={handleSearchInputChange}
//               />
//               <button
//                 type="submit" // Specify the type as "submit" for the button
//                 className="khcaaDirectorylist-search-button"
//               >
//                 Search
//               </button>
//             </div>
//           </form>
//             </div>
//           </div>
//         </div>
  
  
  
//     {dataLength ==0 &&
//     <div className="No-data-container" style={{ display: 'flex', marginTop: '2em', alignItems: 'center', justifyContent: 'center' }}>
//       <h3>No data Found....</h3>
//     </div>
//   }
  
  
  
        
  
//         <div className="khcaaDirectorylist-membercard-lists">
//           <div className="khcaaDirectorylist-membercars-for-advocates">
//             {displayedData.map((advocate) => {
//               // Calculate the length of the advocate's name
//               const isLongName = advocate.user.name.length > 15;
//               const isLongEmail = advocate.user.email.length > 25;
  
//               // Define classes using the classnames library
//               const nameClasses = classNames('khcaaDirectory-advocate-name ', {
//                 'long-name': isLongName,
//               });
//               const emailClasses = classNames('khcaaDirectory-advocate-email', {
//                 'long-email': isLongEmail,
//               });
  
//               // Define additional styles for the long name case
//               const longNameStyles = isLongName ? { fontSize: '21px' } : {};
//               const longEmailStyles = isLongEmail ? { fontSize: '15px' } : {};
//               // const viewProfile = isLongName ? { marginBottom: '10px' } : { marginBottom: '18px' };
              
  
//               return (
//                 <div className="khcaaDirectorylist-profile-card" key={advocate.id}>
//                   <img
//                     className="variety-card-image"
//                     src={
//                       advocate.profile_image
//                         ? advocate.profile_image_url
//                         : advocate.image_link === 0
//                         ? adv_default
//                         : advocate.image_link ?advocate.image_link :  adv_default
//                     }
//                     alt="img"
//                   />
//                   <div className="profile-details">
//                     <h2 className={nameClasses} style={longNameStyles}>
//                       {advocate.user.name}
//                     </h2>
//                     <p className="khcaaDirectory-advocate-court">Advocate, High Court of Kerala</p>
//                     <p className="khcaaDirectory-advocate-number">{advocate.phone}</p>
//                     <p className={emailClasses} style={longEmailStyles}>{advocate.user.email}</p>
//                     {/* <Link className="khcaaDirectory-advocate-viewprofile" to={`/member/detailview/${advocate.id}`} style={{ color: '#B1001E', fontWeight: 'bold' }}>
//                       View Profile <strong>&rarr;</strong>
//                     </Link> */}

//                       <Link
//                         className="khcaaDirectory-advocate-viewprofile"
//                         onClick={() => handleViewProfileClick(advocate.id)}
//                         style={{ color: '#B1001E', fontWeight: 'bold' }}
//                       >
//                         View Profile <strong>&rarr;</strong>
//                       </Link>

//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         </div>
//         <div className="khcaa-directory-paginate">
//       <ReactPaginate
//         previousLabel={<strong>&larr;</strong>}
//         nextLabel={<strong>&rarr;</strong>}
//         breakLabel={'.....'}
//         marginPagesDisplayed={2}
//         pageRangeDisplayed={2}
//         pageCount={Math.ceil(searchDataList.length / itemsPerPage)}
//         onPageChange={handlePageClick}
//         containerClassName={"pagination"}
//         // previousLinkClassName={"pagination-item previous-link"}
//         // nextLinkClassName={"pagination-item next-link"}
//         disabledClassName={"pagination-item-disabled"}
//         subContainerClassName={'pages pagination'}
//         forcePage={currentPage}
//         pageClassName={'page-item'}
//         pageLinkClassName={'page-link'}
//         displayPages={1}
//         activeClassName={'active'}
//       />
   
//   </div>
  
//       </>
//     );

//   }


//   return (

//     <div className="page-content page-container" id="page-content">
//       <div className='advocate-page-content-back-option-parent'>
//         <div className='advocate-page-content-back-option-child'>

//           <button className='advocate-icon-to-go-back-to-directory' onClick={handleNavigate}><span><i class='bx bx-arrow-back'></i></span>Back</button>
//         </div>
//         {/* <div>
//             <input type="text" name="" id="" placeholder='Find lawyer...' className='advocate-icon-to-go-back-and-search-lawyer'/>
//             <button className='btn btn-danger fs-6 ml-2'>Search</button>
//           </div> */}

// <form onSubmit={handleSearchButtonClick}>
//           <div className="KhcaaDirectorylist-searchlawyer">
//             <input
//               type="text"
//               className="khcaaDirectorylist-input-text"
//               placeholder="Search lawyer"
//               value={searchQuery}
//               onChange={handleSearchInputChange}
//             />
//             <button
//               type="submit" // Specify the type as "submit" for the button
//               className="khcaaDirectorylist-search-button"
//             >
//               Search
//             </button>
//           </div>
//         </form>

//         {/* <IoArrowUndoSharp  /> */}
//       </div>
//       <div className="container" ref={componentRef}>

//         <div className="row justify-content-center">

//           <div className="col-md-12">

//             <div className="card user-card-full d-flex flex-column flex-md-row">

//               <div className="bg-c-lite-green user-profile col-md-3 mb-3 col-sm-12">

//                 <div className="text-center text-white">

//                   <div className="m-b-25">
//                     <img
//                       style={{
//                         width: '100% !important',
//                         height: '300px !important',
//                         objectFit: 'cover',
//                       }}
//                       src={data.profile_image ? data.profile_image_url : data.image_link ? data.image_link :  adv_default}
//                       className="img-radius"
//                       alt="img"
//                     />
//                   </div>
//                   <i className="mdi mdi-square-edit-outline feather icon-edit m-t-10 f-16"></i>
//                 </div>
//               </div>
//               <div className="col-md-9 col-sm-12">
//                 <div className="card-block">
//                   <div className='advocate-social-media-site-and-name-parent'>
//                     <div className='advocate-social-medial-and-name-child'>

//                       <h2  className="f-w-600 khcaa-directory-advocate-first-name">
//                         {data.user?.name}
//                       </h2>
//                     </div>
//                     {/* <div>
//                           <ul className='advocate-social-site-and-name-subcontainer-ul'>
//                             <li className='advocate-social-site-and-name-subcontainer-li' ><a href={`https://www.facebook.com/${data.insta_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook" data-abc="true" >
//                              <i className='bx bxl-facebook-square' ></i>
//                            </a></li>
//                             <li className='advocate-social-site-and-name-subcontainer-li'><a href={`https://www.twitter.com/${data.insta_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter" data-abc="true" >
//                              <i className='bx bxl-twitter' ></i>
//                            </a></li>
//                             <li className='advocate-social-site-and-name-subcontainer-li'><a href={`https://www.instagram.com/${data.insta_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram" data-abc="true" >
//                              <i className='bx bxl-instagram' ></i>
//                            </a></li>
//                             <li className='advocate-social-site-and-name-subcontainer-li'>
//                               <a href={`https://www.linkedin.com/${data.insta_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="linkedin" data-abc="true" >
//                              <i className='bx bxl-linkedin-square'></i>
//                            </a>
//                             </li>
//                           </ul>
//                     </div> */}


// <div>
//     <ul className='advocate-social-site-and-name-subcontainer-ul'>
       
//             <li className='advocate-social-site-and-name-subcontainer-li'>
//             {data.fb_id ? (
//                 <a href={`https://www.facebook.com/${data.fb_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook" data-abc="true" >
//                     <i className='bx bxl-facebook-square'></i>
                    
//                 </a>
//                   ):(<i className='bx bxl-facebook-square'></i>) }
           
                  
//             </li>
      
      
//             <li className='advocate-social-site-and-name-subcontainer-li'>
//             {data.twitter_id ? (
//                 <a href={`https://www.twitter.com/${data.twitter_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter" data-abc="true" >
//                     <i className='bx bxl-twitter'></i>
                    
//                 </a>
//                  ):(
//                  <i className='bx bxl-twitter'></i> 
//                  ) }
//             </li>
       
        
//             <li className='advocate-social-site-and-name-subcontainer-li'>
//             {data.insta_id ? (
//                 <a href={`https://www.instagram.com/${data.insta_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram" data-abc="true" >
//                     <i className='bx bxl-instagram'></i>
                    
//                 </a>
//                  ):(
//                  <i className='bx bxl-instagram'></i>
//                  ) }
//             </li>
       
  
//             <li className='advocate-social-site-and-name-subcontainer-li'>
//             {data.linkedin_id ? (
//                 <a href={`https://www.linkedin.com/${data.linkedin_id}`} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="LinkedIn" data-abc="true" >
//                     <i className='bx bxl-linkedin-square'></i>
                    
//                 </a>
//                  ):(
//                  <i className='bx bxl-linkedin-square'></i>
//                  ) }
//             </li>
       
//     </ul>
// </div>





//                   </div>

//                   <h6 className="m-b-20 p-b-5 b-b-default f-w-600 khcaa-advocate-highcourt-name">Advocate, High Court of Kerala</h6>
//                   <div className="row">
//                     <div className="col-sm-12 col-md-6">
//                       <p className="text-muted f-w-400">Email</p>
//                       <div className="d-flex align-items-center khcaa-directory-advocate-email">
//                         {/* <i className="mdi mdi-email-outline m-r-10"></i> */}
//                         <h6 className="m-b-7 f-w-600 ">{data.user?.email}</h6>
//                       </div>
//                     </div>
//                     {/* <div className="col-sm-12 col-md-6">
//                       <p className="m-b-10 f-w-600">Enrollment No:</p>
//                       <div className="d-flex align-items-center">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="text-muted f-w-400">{data.enrollment_id ? data.enrollment_id : '---'}</h6>
//                       </div>
//                     </div> */}
//                     <div className="col-sm-12 col-md-6">
//                       <p className="text-muted f-w-400">Phone</p>
//                       <div className="d-flex align-items-center khcaa-directory-advocate-phone">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="m-b-7 f-w-600">{data.phone ? data.phone : '---'}</h6>
//                       </div>
//                     </div>
//                     {/* <div className="col-sm-12 col-md-6" style={{marginTop:'18px'}}>
//                       <p className="m-b-7 f-w-600">Phone</p>
//                       <div className="d-flex align-items-center">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="text-muted f-w-400">{data.phone ? data.phone : '---'}</h6>
//                       </div>
//                     </div> */}
//                     {/* <div className="col-sm-12 col-md-6">
//                       <p className="m-b-10 f-w-600">Phone</p>
//                       <div className="d-flex align-items-center">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="text-muted f-w-400">{data.phone ? data.phone : '---'}</h6>
//                       </div>
//                     </div> */}
//                     {/* <div className="col-sm-12 col-md-6">
//                       <p className="m-b-10 f-w-600">KHCAA Membership No:</p>
//                       <div className="d-flex align-items-center">
//                         <i className="mdi mdi-phone m-r-10"></i>
//                         <h6 className="text-muted f-w-400">{data.association_membership_id ? data.association_membership_id : '---'}</h6>
//                       </div>
//                     </div> */}
//                     <div className="col-sm-12 col-md-6" style={{marginTop:'20px'}}>
//                       <p className="text-muted f-w-400">Official Address</p>
//                       <div className="d-flex align-items-center">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="m-b-8 f-w-600">{data.address ? data.address : '---'}</h6>
//                       </div>
//                     </div>
//                     <div className="col-sm-12 col-md-6" style={{marginTop:'20px'  }}>
//                       <p className= "text-muted f-w-400">Residential Address</p>
//                       <div className="d-flex align-items-center">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="m-b-8 f-w-600">{data.specialization ? data.specialization : '---'}</h6>
//                       </div>
//                     </div>
//                     <div className="col-sm-12 col-md-6" style={{marginTop:'20px'}}>
//                       <p className="text-muted f-w-400">Status</p>
//                       <div className="d-flex align-items-center khcaa-directory-advocate-enrollment-number">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="m-b-8 f-w-600">ACTIVE</h6>
//                       </div>
//                       {/* {data.enrollment_id ? data.enrollment_id : '---'} */}
//                     </div>
//                     <div className="col-sm-12 col-md-6" style={{marginTop:'20px'}}>
//                       <p className="text-muted f-w-400">KHCAA Membership No</p>
//                       <div className="d-flex align-items-center khcaa-directory-advocate-khcaa-membership">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="m-b-8 f-w-600">{data.khcaa_membership_number ? data.khcaa_membership_number : '---'}</h6>
//                       </div>
//                     </div>
//                     <div className="col-sm-12 col-md-6" style={{marginTop:'20px'}}>
//                       <p className="m-b-8 f-w-600"></p>
//                       <div className="d-flex align-items-center khcaa-directory-advocate-khcaa-membership-active">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="m-b-8 f-w-600" style={{color:'green'}}></h6>
//                       </div>
//                     </div>
//                     <div className="col-sm-12 col-md-6" style={{marginTop:'20px'}}>
//                       {/* <p className="m-b-8 f-w-600">Valid till </p>
//                       <div className="d-flex align-items-center khcaa-directory-advocate-khcaa-membership-active">
//                         <i className="mdi mdi-account-badge-horizontal-outline m-r-10"></i>
//                         <h6 className="text-muted f-w-400">30/11/2024</h6>
//                       </div> */}
//                     </div>
//                   </div>
//                   {/* <div className="row">
//                     <div className="col-sm-12">
//                       <p className="m-b-10 f-w-600">Official Address</p>
//                       <h6 className="text-muted f-w-400">{data.specialization ? data.specialization : '---'}</h6>
//                     </div>
//                     <div className="col-sm-12">
//                       <p className="m-b-10 f-w-600">Residential Address</p>
//                       <h6 className="text-muted f-w-400">{data.address ? data.address : '---'}</h6>
//                     </div>
//                   </div> */}
//                   <ul className="social-link list-unstyled m-t-40 m-b-10">
//                     <div className='khcaa-advocate-directory-social-media-link-for-advocate'>

//                    <div className='khcaa-advocate-directory-social-media-link-for-advocate-sub-container'>

                   
//                     <li >
//                       <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Facebook" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-facebook'>
                        
//                       <i class='bx bxl-facebook-square' ></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Twitter" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-twitter'>
//                       <i class='bx bxl-twitter' ></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Instagram" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-instagram'>
//                       <i class='bx bxl-instagram' ></i>
//                       </a>
//                     </li>
//                     <li>
//                       <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="linkedin" data-abc="true" className='khcaa-advocate-directory-social-media-link-for-linkedin'>
//                       <i class='bx bxl-linkedin-square'></i>
//                       </a>
//                     </li>
//                     </div>
//                     <div className='khcaa-advocate-directory-social-media-link-for-advocate-sub-container-for-print'>
//                     <li>
//                       {/* <a href="#!" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="print" data-abc="true"> */}
//                       <div  onClick={handlePrint}>
//                       <i  className='bx bxs-printer khcaa-advocate-directory-social-media-link-for-print'></i>
//                         </div>
                      
//                       {/* </a> */}
//                     </li>
//                     </div>
//                     </div>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>

//   );
// }





// export default MemberProfile;
  


