import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import axiosInstance from "../../configs/axios/AxiosVonfiguration";
import ReactPaginate from 'react-paginate';
import '../../styles/Directories/directoriememberlist.css';
import LoadingAnimation from "../loader/LoadingAnimation";
import adv_default from '../../assets/images/adv_default.jpg';
import classNames from 'classnames';

function VarietyDirectoryMemberList() {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [storedSearchQuery, setStoredSearchQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(true);
  const itemsPerPage = 16;
  const [noDataMessage, setNoDataMessage] = useState('');
  const [dataLength, setDataLength] = useState(1)
  

  const storedCurrentPage = localStorage.getItem('currentPage');
  console.log(storedCurrentPage,'11111111storedCurrentPage')
  const [currentPage, setCurrentPage] = useState(storedCurrentPage ? parseInt(storedCurrentPage, 10) : 0);
  console.log(currentPage,'111111111currentPage')

  
  const fetchData = async (query) => {
    console.log('fetch dta calling')
    try {
      setLoading(true)
      const response = await axiosInstance.get('advocates/random/list', {
        params: {
          search: query !== '' ? query : null,
          page: pageNumber + 1
        }
      });
      console.log(response.data.length,'length')
      if (response.data.length === 0){
        console.log('No data found')
        setDataLength(0)
      }else{
        setDataLength(response.data.length)
      }
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    console.log('storedSearchQuery changed:', storedSearchQuery);
  fetchData(storedSearchQuery);
  
  let cards = document.querySelectorAll('.variety-card');
  cards.forEach((card) => {
    card.classList.add('fade-in');
  });
}, [storedSearchQuery]);




  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
    localStorage.removeItem('currentPage', selected);

    console.log(selected, '333333333333333333333333333333333');
    if (Math.ceil(data.length / itemsPerPage) <= selected) {
       console.log('Fetching more data...');
      var query = '';
      const page_number_id  = selected + 1;
      localStorage.setItem('currentPage', selected);
      setCurrentPage(selected)
      window.location.reload();
    }
  };

  const handleSearchButtonClick = (e) => {
    e.preventDefault()
    setPageNumber(0);
    console.log('handleSearchButtonClick', searchQuery)
    setStoredSearchQuery(searchQuery);
    // setNoDataMessage('');
  };

  const handleSearchInputChange = (e) => {
    console.log('handleSearchInputChange',e.target.value)
    setSearchQuery(e.target.value);
  };

  const displayedData = data.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  if (loading) {
    return (
      <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999' }}>
        <LoadingAnimation color="#3498db" loading={loading} size={15} />
      </div>
    );
  }

  // if (dataLength){
  //   return (
  //   <div>
  //     <h1>No data Found</h1>
  //   </div>
  //   )
  // }

  return (
    <>
      <div className="khcaaDirectorylist-parent-container">
        <div className="khcaaDirectorylist-search-container">
          <div className="khcaaDirectorylist-findlawyer-container">
            <div className="khcaaDirectorylist-findlawyer">
              <div className="pagination-container">
                {/* ... Pagination JSX ... */}
              </div>
              <p>Find Lawyer</p>
            </div>
            <form onSubmit={handleSearchButtonClick}>
          <div className="KhcaaDirectorylist-searchlawyer">
            <input
              type="text"
              className="khcaaDirectorylist-input-text"
              placeholder="Search lawyer"
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <button
              type="submit" // Specify the type as "submit" for the button
              className="khcaaDirectorylist-search-button"
            >
              Search
            </button>
          </div>
        </form>
          </div>
        </div>
      </div>



  {dataLength ==0 &&
  <div className="No-data-container" style={{ display: 'flex', marginTop: '2em', alignItems: 'center', justifyContent: 'center' }}>
    <h3>No data Found....</h3>
  </div>
}



      

      <div className="khcaaDirectorylist-membercard-lists">
        <div className="khcaaDirectorylist-membercars-for-advocates">
          {displayedData.map((advocate) => {
            // Calculate the length of the advocate's name
            const isLongName = advocate.user.name.length > 15;
            const isLongEmail = advocate.user.email.length > 25;

            // Define classes using the classnames library
            const nameClasses = classNames('khcaaDirectory-advocate-name ', {
              'long-name': isLongName,
            });
            const emailClasses = classNames('khcaaDirectory-advocate-email', {
              'long-email': isLongEmail,
            });

            // Define additional styles for the long name case
            const longNameStyles = isLongName ? { fontSize: '21px' } : {};
            const longEmailStyles = isLongEmail ? { fontSize: '15px' } : {};
            // const viewProfile = isLongName ? { marginBottom: '10px' } : { marginBottom: '18px' };
            

            return (
              <div className="khcaaDirectorylist-profile-card" key={advocate.id}>
                <img
                  className="variety-card-image"
                  src={
                    advocate.profile_image
                      ? advocate.profile_image_url
                      : advocate.image_link === 0
                      ? adv_default
                      : advocate.image_link ?advocate.image_link :  adv_default
                  }
                  alt="img"
                />
                <div className="profile-details">
                  <h2 className={nameClasses} style={longNameStyles}>
                    {advocate.user.name}
                  </h2>
                  <p className="khcaaDirectory-advocate-court">Advocate, High Court of Kerala</p>
                  <p className="khcaaDirectory-advocate-number">{advocate.phone}</p>
                  <p className={emailClasses} style={longEmailStyles}>{advocate.user.email}</p>
                  <Link className="khcaaDirectory-advocate-viewprofile" to={`/member/detailview/${advocate.id}`} style={{ color: '#B1001E', fontWeight: 'bold' }}>
                    View Profile <strong>&rarr;</strong>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="khcaa-directory-paginate">
  {searchQuery !== '' ? (
    <ReactPaginate
      previousLabel={<strong>&larr;</strong>}
      nextLabel={<strong>&rarr;</strong>}
      breakLabel={'.....'}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      pageCount={Math.ceil(data.length / itemsPerPage)}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      // previousLinkClassName={"pagination-item previous-link"}
      // nextLinkClassName={"pagination-item next-link"}
      disabledClassName={"pagination-item-disabled"}
      subContainerClassName={'pages pagination'}
      forcePage={currentPage}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      displayPages={1}
      activeClassName={'active'}
    />
  ) : (
    <ReactPaginate
      // previousLabel={<i className="bx bxs-left-arrow-square"></i>}
      previousLabel={<strong>&larr;</strong>}
      nextLabel={<strong>&rarr;</strong>}
      breakLabel={'.....'}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      pageCount={366}
      onPageChange={handlePageClick}
      containerClassName={"pagination"}
      previousLinkClassName={"pagination-item previous-link"}
      nextLinkClassName={"pagination-item next-link"}
      disabledClassName={"pagination-item-disabled"}
      subContainerClassName={'pages pagination'}
      forcePage={currentPage}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      displayPages={1}
      activeClassName={'active'}
    />
  )}
</div>

    </>
  );
}

export default VarietyDirectoryMemberList;





  // useEffect(() => {
  //   fetchData(storedSearchQuery);
  // }, [storedSearchQuery]);



  // useEffect(() => {
  //   let cards = document.querySelectorAll('.variety-card');
  //   cards.forEach((card) => {
  //     card.classList.add('fade-in');
  //   });
  // }, [data, pageNumber]);

  // const handlePageClick = ({ selected }) => {
  //   setPageNumber(selected);
  //   console.log(selected,'333333333333333333333333333333333')
  //   if (Math.ceil(data.length / itemsPerPage) < selected){
  //     console
  //     var query = '';
  //     fetchData(query)
      
  //   }
  // };


// pageClassName={'page-item'} 
          // pageLinkClassName={'page-link'}
          // renderPage={({ selected, page }) => (
          //   <span key={page} className={selected ? 'active' : ''}>
          //     {selected ? (
          //       <strong style={{ color: page_number_pagination === page + 1 ? 'red' : 'black' }}>
          //         {page + 1}
          //       </strong>
          //     ) : (
          //       <span style={{ color: page_number_pagination === page + 1 ? 'red' : 'black' }}>
          //         {page + 1}
          //       </span>
          //     )}
          //     {selected && ", "}
          //   </span>
          // )}


// import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
// import axiosInstance from "../../configs/axios/AxiosVonfiguration";
// import ReactPaginate from 'react-paginate';
// import '../../styles/Directories/directoriememberlist.css';
// import LoadingAnimation from "../loader/LoadingAnimation";
// import adv_default from '../../assets/images/adv_default.jpg';

// function VarietyDirectoryMemberList() {
//   const [data, setData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [pageNumber, setPageNumber] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const itemsPerPage = 2;

//   const fetchData = async (url, method = 'GET', params = null) => {
//     try {
//       const config = { method, url };
//       if (method === 'GET') {
//         config.params = params;
//       } else if (method === 'POST') {
//         config.data = params;
//       }

//       const response = await axiosInstance(config);
//       console.log('right', response.data)
//       setLoading(false);
//       setData(response.data);
//     } catch (error) {
//       setLoading(false);
//       console.error('Error fetching data:', error);
//     }
//   };

//   useEffect(() => {
//     fetchData('advocates/random/list');
//   }, []); // Initial loading with advocates/random/list

//   useEffect(() => {
//     let cards = document.querySelectorAll('.variety-card');
//     cards.forEach(card => {
//       card.classList.add('fade-in');
//     });
//   }, [data]);

//   const handlePageClick = ({ selected }) => {
//     setPageNumber(selected);
//   };

//   const handleSearchInputChange = (e) => {
//     setSearchQuery(e.target.value);
//     setPageNumber(0); // Reset page number when the search query changes

//     // Send the search query to the backend without using it for frontend filtering
//     fetchData('advocates/search/list', 'POST', { search: e.target.value });
//   };

//   const displayedData = data.slice(
//     pageNumber * itemsPerPage,
//     (pageNumber + 1) * itemsPerPage
//   );

//   if (loading) {
//     return (
//       <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999' }}>
//         <LoadingAnimation color="#3498db" loading={loading} size={15} />
//       </div>
//     );
//   }

//   return (
//     <>
//       <div className="khcaaDirectorylist-parent-container">
//         <div className="khcaaDirectorylist-search-container">
//           <div className="khcaaDirectorylist-findlawyer-container">
//             <div className="khcaaDirectorylist-findlawyer">
//               <div className="pagination-container">
//                 {/* ... Pagination JSX ... */}
//               </div>
//               <p>Find Lawyer</p>
//             </div>
//             <div className="KhcaaDirectorylist-searchlawyer">
//               <input
//                 type="text"
//                 className="khcaaDirectorylist-input-text"
//                 placeholder="Search lawyer"
//                 value={searchQuery}
//                 onChange={handleSearchInputChange}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="khcaaDirectorylist-membercard-lists">
//         <div className="khcaaDirectorylist-membercars-for-advocates">
//           {displayedData.map((advocate) => (
//             <div className="khcaaDirectorylist-profile-card individual-hover" key={advocate.id}>
//               <img className="variety-card-image" src={advocate.profile_image ? advocate.profile_image_url : (advocate.image_link == 0 ? adv_default : (advocate.image_link || adv_default))} alt="Advocate Name"/>
//               <div className="profile-details">
//                 <h2 className="khcaaDirectory-advocate-name">{advocate.user.name}</h2>
//                 <p className="khcaaDirectory-advocate-court">High Court of Kerala, Advocate</p>
//                 <p className="khcaaDirectory-advocate-number">{advocate.phone}</p>
//                 <p className="khcaaDirectory-advocate-email">{advocate.user.email}</p>
//                 <Link to={`/member/detailview/${advocate.id}`} style={{ color: '#B1001E', fontWeight: 'bold' }}>View Profile <strong>&rarr;</strong></Link>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="khcaa-directory-paginate">
        // <ReactPaginate
        //   previousLabel={"Previous"}
        //   nextLabel={"Next"}
        //   breakLabel={'...'}
        //   marginPagesDisplayed={2}
        //   pageRangeDisplayed={5}
        //   pageCount={Math.ceil(data.length / itemsPerPage)}
        //   onPageChange={handlePageClick}
        //   containerClassName={"pagination"}
        //   previousLinkClassName={"pagination-item previous-link"} 
        //   nextLinkClassName={"pagination-item next-link"}       
        //   disabledClassName={"pagination-item-disabled"}
        //   subContainerClassName={'pages pagination'}
        // />
//       </div>
//     </>
//   );
// }

// export default VarietyDirectoryMemberList;









// import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
// import axiosInstance from "../../configs/axios/AxiosVonfiguration";
// import images from "../../constants/images";
// import ReactPaginate from 'react-paginate';
// import '../../styles/Directories/directoriememberlist.css';
// import LoadingAnimation from "../loader/LoadingAnimation";

// function VarietyDirectoryMemberList() {
//   const [data, setData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [pageNumber, setPageNumber] = useState(0);
//   const [loading, setLoading] = useState(true)
//   const itemsPerPage = 16;

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axiosInstance.get('userapp/khcaa-adv-member-directory-listing');
//         setLoading(false)

//         setData(response.data);
//       } catch (error) {
//         setLoading(false)
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [searchQuery]);

//   useEffect(() => {
//     let cards = document.querySelectorAll('.variety-card');
//     cards.forEach(card => {
//       card.classList.add('fade-in');
//     });
//   }, [data]);

//   const pageCount = Math.ceil(data.length / itemsPerPage);

//   const handlePageChange = ({ selected }) => {
//     console.log(selected,'tttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt')
//     setPageNumber(selected);
//   };

//   const startIndex = pageNumber * itemsPerPage;
//   const endIndex = startIndex + itemsPerPage;
//   const displayedItems = data.slice(startIndex, endIndex);
//   console.log(displayedItems,'displkkkkkkkkkkkkkayedItems')

//    if (loading) {
//      return (
//        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999' }}>
//          <LoadingAnimation color="#3498db" loading={loading} size={15} />
//        </div>
//      );
//    }


//   return (
//     <>
//       <div className='variety-memberdirectory-maincontainer'>
//         <div className="variety-memberdirectory-subcontainer">
//           <div className="variety-member-searchcontainer">
//             <p className='variety-member-find-lawyer'>Find Lawyer</p>
//             <input
//               type="text"
//               name=""
//               id=""
//               className='variety-member-directory-search'
//               placeholder='Search...'
//               onChange={(e) => setSearchQuery(e.target.value)}
//             />
//           </div>
//           <div className='variety-directory-member-list'>
//             <div className="row">
//               {displayedItems.map(advocate => (
//                 <div
//                   key={advocate.id}
//                   className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3"
//                 >
//                   <div className="variety-card">
//                     <div className="variety-card-image-container">
//                       <img
//                         src={advocate.image_link == 0 ? images.avt : advocate.image_link}
//                         alt={images.avt}
//                         className="variety-card-image"
//                         style={{ width: '100%', height: '300px', objectFit: 'cover' }}
//                       />
//                       <div className="variety-card-overlay"></div>
//                     </div>
//                     <div className="variety-card-container">
//                       <h4 style={{ textAlign: 'left' }}><b style={{ color: 'black' }}>{advocate.name}</b></h4>
//                       <p style={{ fontFamily: 'cursive', marginBottom: '12px', color: '#909090', textAlign: 'left' }}>High Court of Kerala, Advocate</p>
//                       <p style={{ textAlign: 'left' }}>{advocate.phone}</p>
//                       <p style={{ textAlign: 'left' }}>{advocate.email}</p>
//                       <Link to={`/member/detailview/${advocate.id}`} style={{ color: '#B1001E', fontWeight: 'bold' }}>View Profile <strong>&rarr;</strong></Link>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>

//         <div className="pagination-container">
//           <ReactPaginate
//             previousLabel={'previous'}
//             nextLabel={'next'}
//             breakLabel={'...'}
//             breakClassName={'break-me'}
//             pageCount={pageCount}
//             marginPagesDisplayed={2}
//             pageRangeDisplayed={5}
//             onPageChange={handlePageChange}
//             containerClassName={'pagination'}
//             subContainerClassName={'pages pagination'}
//             activeClassName={'active'}
//           />
//         </div>
//       </div>
//     </>
//   );
// }

// export default VarietyDirectoryMemberList;








// /* General Styles */
// .variety-memberdirectory-maincontainer {
//   width: 100%;
//   height: 100vh;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .variety-memberdirectory-subcontainer {
//   width: 100%;
//   max-width: 1000px;
//   padding: 0 20px;
//   height: 100vh; /* Added height to subcontainer */
// }

// .variety-member-searchcontainer {
//   display: flex;
//   justify-content: center; /* Center the content horizontally */
//   align-items: center; /* Center the content vertically */
//   flex-wrap: wrap; /* Allow content to wrap to the next line on smaller screens */
//   margin-top: 30px;
// }

// .variety-member-find-lawyer {
//   font-size: 25px;
//   color: #b1001e;
//   font-weight: 800;
//   margin-right: 20px; /* Added margin-right for spacing */
//   margin-bottom: 10px; /* Added margin-bottom to separate from the input */
  
// }

// .variety-member-directory-search {
//   background-color: rgb(227, 227, 227);
//   border-radius: 5px;
//   padding: 10px;
//   border: none;
//   margin-bottom: 50px;
//   width: 100%;
//   max-width: 300px;
// }

// .variety-directory-member-list {
//   padding: 10px;
// }

// /* Responsive Styles */
// @media screen and (max-width: 640px) {
//   .variety-member-directory-subcontainer .variety-member-searchcontainer {
//       flex-direction: column;
//       align-items: center;
//   }

//   .variety-member-find-lawyer {
//       font-size: 15px;
//       margin: 10px 0;
//       white-space: nowrap;
//   }



//   .variety-member-directory-search {
//       margin: 10px 0 20px;
//   }

//   .variety-directory-member-list {
//       padding: 10px;
//   }
// }

// /* Card Styles */
// .variety-card {
//   border: 1px solid #ccc;
//   max-width: 100%;
//   width: 300px;
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
//   opacity: 0;
//   transform: translateY(20px);
//   transition: opacity 0.3s, transform 0.3s;
// }

// .variety-card-image-container {
//   position: relative;
//   width: 100%;
//   height: 300px;
//   overflow: hidden;
// }

// .variety-card-image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   transition: filter 0.3s ease-in-out;
// }

// .variety-card-overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(255, 255, 255, 0.5);
//   content: '';
//   transition: transform 0.3s ease, opacity 0.3s ease;
//   transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -110%, 0);
//   opacity: 0;
// }

// .variety-card-image-container:hover .variety-card-overlay {
//   opacity: 0.8;
//   transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 0deg) translate3d(0, 0, 0);
//   background: rgba(196, 53, 53, 0.8);
// }

// .variety-card:hover {
//   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
// }

// .variety-card:hover .variety-card-container {
//   background-color: #4e4a4a;
//   color: #b1001e;
//   transition: background-color 0.3s ease, color 0.3s ease;
// }

// .variety-card:hover .variety-card-container h4,
// .variety-card:hover .variety-card-container p {
//   color: white;
// }

// .variety-card .variety-card-container {
//   padding: 16px;
//   transition: background-color 0.3s ease, color 0.3s ease;
// }

// .variety-card .variety-card-container h4 {
//   font-size: 20px;
//   color: #b1001e;
//   text-align: center;
//   margin-bottom: 2px;
// }

// .variety-card .variety-card-container p {
//   font-size: 16px;
//   color: #333;
//   text-align: center;
// }

// .variety-card.fade-in {
//   opacity: 1;
//   transform: translateY(0);
// }

// /* Additional Styles for Search Centering */
// .variety-member-search-center {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
// }

// .variety-member-find-lawyer {
//   margin-right: 20px; /* Adjust the margin as needed */
//   white-space: nowrap; 
//   padding-bottom: 35px;


// }

// .variety-member-directory-search {
//   background-color: rgb(227, 227, 227);
//   border-radius: 5px;
//   padding: 10px;
//   border: none;
//   margin-bottom: 50px;
//   width: 100%;
//   max-width: 300px;
// }

// .variety-card-image-container {
//   position: relative;
//   width: 100%;
//   max-height: 250px; /* Adjust the max-height as needed */
//   overflow: hidden;
// }

// .variety-card-image {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   transition: filter 0.3s ease-in-out;
// }

// /* Responsive Styles */
// @media screen and (max-width: 640px) {
//   /* ... existing styles ... */
// }

// /* Additional Styles for Search Centering */
// .variety-member-search-center {
//   /* ... existing styles ... */
// }

// /* Add a new class for smaller image containers on smaller screens */
// @media screen and (max-width: 640px) {
//   .variety-card-image-container.smaller {
//       max-height: 200px; /* Adjust the max-height as needed for smaller screens */
//   }
// }

// /* Apply the new class on smaller screens */
// @media screen and (max-width: 640px) {
//   .variety-card:hover .variety-card-image-container {
//       max-height: 200px; /* Adjust the max-height as needed for smaller screens */
//   }
// }


// /* General Styles */
// .variety-memberdirectory-maincontainer {
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: #f2f2f2; /* Add background color */
// }

// .variety-memberdirectory-subcontainer {
//   width: 100%;
//   max-width: 1200px; /* Increase the max-width for larger screens */
//   padding: 0 20px;
// }

// .variety-directory-member-list {
//   display: flex;
//   flex-wrap: wrap; /* Ensure flex items wrap to the next line */
//   justify-content: space-around; /* Add space around items */
// }

// /* Card Styles */
// .variety-card {
//   border: 1px solid #ccc;
//   max-width: 100%;
//   width: 300px;
//   border-radius: 10px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   overflow: hidden;
//   opacity: 0;
//   transform: translateY(20px);
//   transition: opacity 0.3s, transform 0.3s;
//   margin: 10px; /* Add margin for space between cards */
// }

// .variety-card-image-container {
//   position: relative;
//   width: 100%;
//   height: 300px; 
//   overflow: hidden;
// }





// /* Pagination Styles */
// .pagination-container {
//   display: flex;
//   justify-content: center;
//   margin-top: 20px;
// }

// .pagination {
//   display: flex;
//   list-style: none;
//   padding: 0;
//   margin: 0;
// }

// .pagination-item {
//   margin: 0 5px;
// }

// .pagination-link {
//   text-decoration: none;
//   padding: 8px 12px;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   color: #333;
//   transition: background-color 0.3s ease;
// }

// .pagination-link:hover {
//   background-color: #f2f2f2;
// }



















































// Import necessary modules and styles
// import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
// import '../../styles/Directories/directoriememberlist.css';
// import axiosInstance from "../../configs/axios/AxiosVonfiguration";
// import LoadingAnimation from "../loader/LoadingAnimation";

// function VarietyDirectoryMemberList() {
//   const [data, setData] = useState([]);
//   const [searchQuery, setSearchQuery] = useState('');
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axiosInstance.get('userapp/khcaa-adv-member-directory-listing');
//         setData(response.data);
//         setLoading(false);
//       } catch (error) {
//         setLoading(false);
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, [searchQuery]);

//   useEffect(() => {
//     let cards = document.querySelectorAll('.variety-card');
//     cards.forEach(card => {
//       card.classList.add('fade-in');
//     });
//   }, [data]);

//   if (loading) {
//     return (
//       <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', background: 'rgba(255, 255, 255, 0.7)', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: '999' }}>
//         <LoadingAnimation color="#3498db" loading={loading} size={15} />
//       </div>
//     );
//   }

//   return (
//     <>
//       <div className='variety-memberdirectory-maincontainer'>
//         <div className="variety-memberdirectory-subcontainer ">
//           <div className="variety-member-searchcontainer">
//             <div className='variety-member-search-center '>
//               <p className='variety-member-find-lawyer'>Find Lawyer</p>
//               <input
//                 type="text"
//                 name=""
//                 id=""
//                 className='variety-member-directory-search'
//                 placeholder='Search...'
//                 onChange={(e) => setSearchQuery(e.target.value)}
//               />
//             </div>
//           </div>
//           <div className='variety-directory-member-list row '>
//             {data.map(advocate => (
//               <div 
//                 key={advocate.id}
//                 className="col-xl-3 col-lg-4 col-md-6 col-sm-12 mb-3"
//                       >
//                 <div className="variety-card ">
//                   <img
//                     src={advocate.image_link || '../assets/images/khcaa__adv_default_img.jpeg'}
//                     alt="Avatar"
//                     className="variety-card-image"
//                     style={{ width: '100%', height: '300px', objectFit: 'cover' }}
//                   />
//                   <div className="variety-card-overlay"></div>
//                   <div className="variety-card-container">
//                     <h4 style={{ textAlign: 'left' }}><b style={{ color: 'black' }}>{advocate.name}</b></h4>
//                     <p className="variety-card-image-text" style={{ fontFamily: 'italic', marginBottom: '12px', color: '#909090', textAlign: 'left' }}>High Court of Kerala, Advocate</p>
//                     <p style={{ textAlign: 'left' }}>{advocate.phone}</p>
//                     <p style={{ textAlign: 'left' }}>{advocate.email}</p>
//                     <Link to={`/member/detailview/${advocate.id}`} style={{ color: '#B1001E', fontWeight: 'bold' }}>View Profile <strong>&rarr;</strong></Link>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// export default VarietyDirectoryMemberList;

