import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axiosInstance from '../../../configs/axios/AxiosVonfiguration'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import useRazorpay from 'react-razorpay';



function PaymentModal({ associationid }) {

  const [show, setShow] = useState(false);
  const [membership, setMembership] = useState([])
  const [membershipid, setMembershipid] = useState(null)
const navigate=useNavigate()
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const token=localStorage.getItem('advocateToken')
  const user = localStorage.getItem('advocateData')

  // useEffect(() => {
  //   axiosInstance.get(`/association/membership-plan/list/${associationid}`,{
  //        headers:{
  //         'Authorization':`Bearer ${token}`
  //        }
  //   })
  //     .then((response) => {
  //       setMembership(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },[]);

  // console.log(associationid);

  const ImpersonateId = localStorage.getItem('id')

  const advocateid = ImpersonateId ? localStorage.getItem('advocateToken') : null;

  const adv_token = localStorage.getItem('advocateToken');

  const token = adv_token ? localStorage.getItem('advocateToken') : localStorage.getItem('accessToken')
  // console.log('membershipid', membershipid);

  useEffect(() => {
    let endpoint = `/association/membership-plan/list/${associationid}`       
    if (advocateid) {
      endpoint +=`/${advocateid}`
    }
    axiosInstance.get(endpoint,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
      .then((response) => {

        console.log("API Response:", response.data);
        setMembership(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  // const url = 'association/Paymentsucessfull/1/2/3'
  // axiosInstance.post(url, {
  //   headers: { 
  //     'Authorization': `Bearer ${token}`
  //   }
 const complete_order = (paymentID, orderID, signature) => {
  const url = `association/Paymentsucessfull/${paymentID}/${orderID}/${signature}`
  axiosInstance.get(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
    .then((response) => {
      if (response.data.message === 'Payment sucessfull') {
        const message = response.data.message;
        navigate(`/advocate/success?message=${encodeURIComponent(message)}`);
      }
      console.log(response.data);
      const message = response.data.message;
      navigate(`/advocate/success?message=${encodeURIComponent(message)}`);
    })
    .catch((error) => {
      console.log(error.response.data);
    });
};



  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('1');
    console.log('associationid', associationid);
    console.log('2');
    console.log('user', user);
    console.log('3');
    console.log('membershipid', membershipid);
    console.log('4');
    if (!membershipid) {
      
      toast.error('Please select a membership plan');
      return;
    }
    const url = `association/membership-payment/create/${user}/${membershipid}/${associationid}`
    axiosInstance.post(url, {
      headers: { 
        'Authorization': `Bearer ${token}`
      }
    }, {

    })
      .then((response) => {

        console.log(response.data);
        // const urll = response.data.payment_request_url
        // window.location.href = urll;

        

        var order_id = response.data.data.id
        console.log(order_id, '999999999999999999999999999999999999999')
            
        // handle payment
        const options = {
            key: 'rzp_test_RRhMTrJphOc3D7', // Enter the Key ID generated from the Dashboard
            name: "Razorpay Gateway Corp",
            description: "Membership Transaction",
            image: "https://example.com/your_logo",
            order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {
              console.log('response.razorpay_payment_id :',response.razorpay_payment_id );
              console.log('response.razorpay_order_id :',response.razorpay_order_id );
              console.log('response.razorpay_signature :',response.razorpay_signature );
  
              
              //complete order
                complete_order(
                    response.razorpay_payment_id,
                    response.razorpay_order_id,
                    response.razorpay_signature
                )
            },
            prefill: {
            name: response.data.datas.user.name,
            email: response.data.datas.user.email,
            contact: response.data.datas.phone,
            },
            notes: {
            address: "Razorpay Corporate Office",
            },
            theme: {
            color: "#3399cc",
            },
        };
        console.log('ooooooooooooooooooooooooooooooooooooooooooooooo')
        const rzp1 = new window.Razorpay(options);
        console.log('2222222222222222222222222222222222222222222')

        rzp1.on("payment.failed", function (response) {
          console.log('qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq')
            alert(response.error.code,'order_id');
            alert(response.error.description,'order_id');
            alert(response.error.source,'order_id');
            alert(response.error.step,'order_id');
            alert(response.error.reason,'order_id');
            alert(response.error.metadata.order_id,'order_id');
            alert(response.error.metadata.payment_id,'order_id');
        });
        rzp1.open();



      })
      .catch((error) => {
        console.log(error,'7777777777777777777777777777777777');
      });
  }

  return (

    <>
      <Button variant="primary" className='hadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-light py-2 px-4 rounded border-none' onClick={handleShow}>
        Join
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select membership plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       




          <form id='payment' onSubmit={handleSubmit}>
            <div className="container mx-auto">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

                {membership.map((membership) => (
                  <div
                    key={membership.id}
                    className={`p-4 border rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${membershipid === membership.id ? 'bg-green-200' : 'bg-white'
                      }`}
                    onClick={() => setMembershipid(membership.id)}
                  >
                    <div className="mb-2 text-lg font-semibold">
                      <span className="font-bold">Duration: </span>
                      {membership.duration}
                    </div>
                    <div className="mb-2 text-gray-700">
                      <span className="font-bold">Unit of Plan: </span>
                      {membership.unit_of_plan}
                    </div>
                    <div className="mb-2 text-green-500">
                      <span className="font-bold">Amount: </span>
                      ₹{membership.membership_price}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </form>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button type='submit' form='payment' variant="primary" onClick={membershipid ? handleClose : handleShow}>Take membership</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}




export default PaymentModal;