import React, { useEffect,useState } from 'react'

import Button from 'react-bootstrap/Button';
import axiosInstance from '../../configs/axios/AxiosVonfiguration';
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import { Associationlist, data } from '../../constants/Advocate/AssociationData';
import '../../styles/advocates/associationmodallist.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import PaymentModal from '../../components/advocateDashboard/payment/PaymentModal';

function MyVerticallyCenteredModal(props) {

    const ImpersonateId = localStorage.getItem('id')
    const advcoatedata = ImpersonateId ? localStorage.getItem('advid') : null;
    const advtoken = localStorage.getItem('advocateToken')
    const token = advtoken ? localStorage.getItem('advocateToken') : localStorage.getItem('accessToken')
    const navigate = useNavigate()
    const [membership, setMembership] = useState([])
    const [membershipid, setMembershipid] = useState(null)
    const [hasJoined, setHasJoined] = useState(false);
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredAssociation, setFilteredAssociation] = useState(props.Association);


    useEffect(() => {
        setFilteredAssociation(props.Association);
      }, [props.Association]);

      
      useEffect(() => {
        const filteredResults = props.Association.filter((association) =>
          association.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredAssociation(filteredResults);
      }, [searchTerm, props.Association]);



    const handleJoinClick = async (associationId) => {
        try {
            setSelectedRowId(associationId)
            let endpoint = `/association/membership-plan/list/${associationId}`
            if (advcoatedata) {
                endpoint += `/${advcoatedata}`
            }
            const response = await axiosInstance.get(endpoint, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            )
            setMembership(response.data)
            setHasJoined(true);
        } catch (error) {
            console.log(error);
        }
    }
    const columnWithJoinButton = [
        ...Associationlist,
        {
            name: 'join',
            cell: (row) => (
                  <PaymentModal associationid={row.id} />
                // <button className='shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-light py-2 px-3 rounded border-none'  onClick={() => handleJoinClick(row.id)}>Join</button>
            ),
            sortable: false,
        }
    ]

    const user = localStorage.getItem('advocateData')


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('1');
        console.log('associationid', selectedRowId);
        console.log('2');
        console.log('user', user);
        console.log('3');
        console.log('membershipid', membershipid);
        console.log('4');
        if (!membershipid) {

            toast.error('Please select a membership plan');
            return;
        }
        const url = `association/membership-payment/create/${user}/${membershipid}/${selectedRowId}`
        axiosInstance.post(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }, {

        })
            .then((response) => {

                console.log(response.data);
                const urll = response.data.payment_request_url
                window.location.href = urll;
                if (response.data.message === 'Payment unsucessfull') {
                    navigate('advocate/success')
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const handleBackButton = () => {
        setHasJoined(false)
    }

    const renderContent = () => {
        if (hasJoined) {
            return (
                <div>
                    <button style={{ marginBottom: '10px' }} onClick={handleBackButton}><i class='bx bx-arrow-back'></i></button>
                    <form id='payment' onSubmit={handleSubmit}>
                        <div className="container mx-auto">
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">

                                {membership.map((membership) => (
                                    <div
                                        key={membership.id}
                                        className={`p-4 border rounded-md shadow-md transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer ${membershipid === membership.id ? 'bg-green-200' : 'bg-white'
                                            }`}
                                        onClick={() => setMembershipid(membership.id)}
                                    >
                                        <div className="mb-2 text-lg font-semibold">
                                            <span className="font-bold">Duration: </span>
                                            {membership.duration}
                                        </div>
                                        <div className="mb-2 text-gray-700">
                                            <span className="font-bold">Unit of Plan: </span>
                                            {membership.unit_of_plan}
                                        </div>
                                        <div className="mb-2 text-green-500">
                                            <span className="font-bold">Amount: </span>
                                            ₹{membership.membership_price}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </form>

                </div>
            );
        } else {

            return (
                <DataTable
                    columns={columnWithJoinButton}
                    data={filteredAssociation}
                    responsive={{
                        small: true,
                        medium: false,
                        large: true,
                    }}
                    fixedHeader
                    pagination
                />
            );
        }
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">

                    <div className='find-association'>
                        <div>

                            {/* <p style={{ fontWeight: 'normal' }} className='find-association-heading' >Find Association To Take Membership</p> */}
                            {hasJoined ? (
                                <p style={{ fontWeight: 'normal' }} className='find-association-heading' >Choose membership plan To Take Membership</p>
                            ) : (
                                <p style={{ fontWeight: 'normal' }} className='find-association-heading' >Find Association To Take Membership</p>
                            )}
                        </div>
                        <div>
                            {
                                hasJoined ? (
                                     null
                                ):(
                                    <input type="text" name="" id="" placeholder='Search Association' className='bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight fs-6 focus:outline-none focus:bg-white focus:border-purple-500  find-association-search' value={searchTerm}  onChange={(e) => setSearchTerm(e.target.value)} />
                                )
                            }

                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* <DataTable
                    columns={columnWithJoinButton}
                    data={props.Association}
                    responsive={{
                        small: true,
                        medium: false,
                        large: true,
                    }}
                    fixedHeader
                    pagination
                /> */}
                {renderContent()}
            </Modal.Body>

            <Modal.Footer>
                {/* <Button type='submit' form='payment' variant="primary" onClick={membershipid ? handleClose : handleShow}>Take membership</Button> */}

                <Button onClick={props.onHide}>Close</Button>
                {
                    hasJoined && <button type='submit' form='payment' variant="primary" className='shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-light py-1 px-3 rounded border-none'>Take membership</button>
                }



            </Modal.Footer>
        </Modal>
    );
}

function AssociationListModal() {

    const [modalShow, setModalShow] = React.useState(false);
    const [Association, setAssociation] = useState([])

    const ImpersonateId = localStorage.getItem('id')
    const advcoatedata = ImpersonateId ? localStorage.getItem('advid') : null;
    const advtoken = localStorage.getItem('advocateToken')
    const token = advtoken ? localStorage.getItem('advocateToken') : localStorage.getItem('accessToken')

    const handleClick = () => {

        let association = '/advocates/list-associations';
        if (advcoatedata) {
            association += `/${advcoatedata}`
        }
        axiosInstance.get(association, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            console.log('response datasss',response.data);
            const newIdAndNames = response.data.map(({ id, name, address
            }) => ({
                id, name, address
            }))
            setAssociation(newIdAndNames)

        }).catch((error) => {
            console.log(error);
        })

        setModalShow(true)

    }

    return (
        <>
            <Button variant="primary" className='shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white font-light py-2 px-4 rounded border-none' onClick={handleClick}>
               Find Association
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                Association={Association}
            />
        </>
    );
}
export default AssociationListModal