import React from "react";
import MemberDirectoryNav from "../../components/Directories/MemberDirectoryNav";
import MemberDirectoryFooter from "../../components/Directories/MemberDirectoryFooter";
import MemberProfile from "../../components/advocateDashboard/memberdetailview/MemberProfile";


function DetailView() {
    return(
        <>
        <div className='advocate-detailview'>
        {/* <p>Detail Vew</p> */}

            <MemberDirectoryNav/>
            <MemberProfile/>
            <MemberDirectoryFooter/>
            
        </div>
        </>
    )
}

export default DetailView