import React from "react";
// import '../../style/AdvocateDirectories/khcaaDirectoryfooter.css'

import '../../styles/Directories/memberfooter.css';


function MemberDirectoryFooter() {
    return (
        <div className="khcaaDirectoryfooter-parent">
            <div className="khcaaDirectoryfooter-child">
                <div className="khcaaDirectoryfooter-div-for-first-box">


                    <div className="khcaaDirectoryfooter-div-for-contacts">
                        <h3>CONTACTS</h3>
                        <address className="khcaaDirectoryfooter-div-for-address">
                            KHCAA, High Court of Kerala, Ernakulam,<br/> Kerala, 682031,
                        </address>
                        <p className="khcaaDirectoryfooter-div-for-mobile-number">+91-0484-2393244</p>
                        <p className="khcaaDirectoryfooter-div-for-land-number">0484-2394435</p>
                        <p className="khcaaDirectoryfooter-div-for-yahoo-mail">k.hcaa@yahoo.com</p>
                    </div>
                    {/* <div className="khcaaDirectoryfooter-div-for-recent-posts">
                        <h3>RECENT POSTS</h3>
                        <p className="khcaaDirectoryfooter-div-for-printing-invited">Calender Printing – Quotations are <br /> invited</p>
                        <hr />
                        <p className="khcaaDirectoryfooter-div-for-renewal-membership">Renewal of Ordinary Membership- <br />Last date 19.11.2023</p>
                        <hr />
                        <p className="khcaaDirectoryfooter-div-for-siting-notice">Sitting Notice</p>
                    </div> */}
                    <div className="khcaaDirectoryfooter-div-for-recent-posts">
                        <h3>RECENT POSTS</h3>
                        <a href="https://www.khcaa.com/2023/10/26/calender-printing-quotations-are-invited/">
                        <p className="khcaaDirectoryfooter-div-for-printing-invited"><span>Calender Printing – Quotations are <br /> invited</span></p>
                        </a>
                        <hr />
                        <a href="https://www.khcaa.com/2023/10/26/renewal-of-ordinary-membership-last-date-19-11-2023/">
                        <p className="khcaaDirectoryfooter-div-for-renewal-membership">Renewal of Ordinary Membership- <br />Last date 19.11.2023</p>
                        </a>
                        <hr />
                        <a href="https://www.khcaa.com/2023/10/20/sitting-notice-174/">
                        <p className="khcaaDirectoryfooter-div-for-siting-notice">Sitting Notice</p>
                        </a>
                    </div>

                </div>
                <hr className="khcaaDirectorfooter-line-for-hr-line" />
                <div className="khcaaDireectorfooter-line-images">
                    <div>
                        <img src="http://www.khcaa.com/wp-content/uploads/2020/12/Header-Logo.png" style={{width:'60%'}} alt="khcaa" />

                    </div>
                    <div>
                    <i class='bx bxl-facebook-square'></i>
                    <i class='bx bxl-youtube' ></i>
                    </div>

                </div>
                <hr className="khcaaDirectorfooter-line-for-hr-line" />

                <div className="khcaaDireectorfooter-maintained-persons">
                    <div>
                        <p className="khcaaDirectoryfooter-div-for-copy-right">Copyright © 2020 - 2023. KHCAA. All rights reserved</p>
                    </div>
                    <div>
                        {/* <p className="khcaaDirectoryfooter-div-for-developed-by"> Administered by Adarsh S(Member-KHCAA), Developed & Maintained by Netmagics</p> */}
                        <p className="khcaaDirectoryfooter-div-for-developed-by"> Website developed by <a href="http://khcaa.org/#/member/detailview/12"><span style={{ color: '#b1001e' }}><b>Adv.Adarsh S(Member-KHCAA)</b></span></a>,  Hosted and Maintained by <a href="http://netmagics.com"><span style={{ color: '#b1001e' }}><b>Netmagics</b></span></a></p>
                    </div>

                </div>


            </div>
        </div>
    )
}
export default MemberDirectoryFooter