const associationNav = [
    
    {
        link: '/advocate/layout',
        section: 'home',
        icon: <i className='bx bx-home-alt'></i>,
        text: 'Home'
    }
    ,
    {
        link: '/advocate/layout/membership',
        section: 'home',
        icon: <i className='bx bx-cube'></i>,
        text: 'Membership'
    },
    
{
    linkk: '',
    section: 'home',
    // icon: <i className='bx bx-cube'></i>,
    text: ''
},


//     Non enabled
//     {
//         linkk: '',
//         section: 'home',
//        //  icon: <i className='bx bx-receipt'></i>,
//         text: ''
//     },{
//        linkk: '',
//        section: 'home',
//        // icon: <i className='bx bx-cube'></i>,
//        text: ''
//    },



// {
//     link: '/advocate/layout/association',
//     section: 'home',
//     icon: <i className='bx bx-receipt'></i>,
//     text: 'Association'
// },


// ENABLED ONE
//     {
//         linkk: '/advocate/layout/association',
//         section: 'home',
//         icon: <i className='bx bx-receipt'></i>,
//         text: 'Association'
//     },{
//        linkk: '/advocate/layout/lawfirm',
//        section: 'home',
//        icon: <i className='bx bx-cube'></i>,
//        text: 'Lawfirm'
//    },



]
export default associationNav




    // {
    //     link: '/advocate/layout/success',
    //     // section: 'home',
    //     // icon: <i className='bx bx-cube'></i>,
    //     // text: 'Membership'
    // }
    
    // {
    //     link: '/association/lawfirm',
    //     section: 'home',
    //     icon: <i className='bx bx-user'></i>,
    //     text: 'lawfirm'
    // },
    // {
    //     link: '/association/advocates',
    //     section: 'home',
    //     icon: <i className='bx bx-user'></i>,
    //     text: 'advocate'
    // },
    // {
    //     link: '/association/announcement',
    //     section: 'home',
    //     icon: <i className='bx bx-user'></i>,
    //     text: 'Announcement'
    // },

